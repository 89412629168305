import React, { useState, useRef } from 'react';
import "./Main.css";
import TestimonialCard from "../../Component/testimonialcard/TestimonialCard";
import ServiceCard from "../../Component/ServiceCard/ServiceCard";
import BBCCard from "../../Component/BBCCard/BBCCard";
import BlockCard from "../../Component/blockcard/BlockCard";
import testi1 from "../../Assets/ITSFibernet.png";
import testi2 from "../../Assets/mmdf.png";
import testi3 from "../../Assets/avondale.png";
import testi4 from "../../Assets/UKCE.png";
import DM from "../../Assets/digital-marketing.jpg";
import WD from "../../Assets/web-development.jpg";
import CC from "../../Assets/cloud-computing.webp";
import FA from "../../Assets/financial-advisor.jpg";
import BN from "../../Assets/Business Networking.jpg";
import ITIM from "../../Assets/IT infrastructure.jpg";
import IntroBBC from "../../Assets/BBCIntroduction.mp4";
import Connect from "../../Assets/people-connect.png"
import GrowthImg from "../../Assets/growth-img.png"
import TopLeftBorder from "../../Assets/top-left-border.png"
import BottomRightBorder from "../../Assets/bottom-right-border.png"
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import emailjs from '@emailjs/browser';
import { FaStarOfLife } from "react-icons/fa6";
import { Helmet } from 'react-helmet';
import { Img } from 'react-image';

const MainPage = () => {

  const [submitted, setSubmitted] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_npn6dpp', 'template_dfuizfk', form.current, {
        publicKey: 'FdR2GwMya2A43s8rB',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setSubmitted(true); // Set state to indicate form submission
          form.current.reset(); // Reset form fields
          // setCaptchaVal(null); // Reset reCAPTCHA
        },
        (error) => {
          console.log('FAILED...', error.text);
          // Handle error scenario if needed
        }
      );
  };

  return (
    <>
      <Helmet>
        <title>BizzBuzz Creations | Innovative & Integrated Everyday</title>
        <meta
          name="description"
          content="BizzBuzz Creations | Innovative & Integrated Everyday - Expert in Web Development, Digital Marketing, Financial Advisory, IT Management, and Corporate Solutions."
        />
        <meta
          name="keywords"
          content="web development services in prayagraj, best website development in prayagraj, SMM services in prayagraj, best SMM services in prayagraj, digital marketing services and agency in prayagraj, best digital marketing agency in prayagraj, digital marketing in prayagraj, cloud computing services in prayagraj, financial advisory services in prayagraj, BPO Outsourcing services in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
      </Helmet>
      <section className="bg-dark">
        <video width="100%" height="100%" autoPlay loop>
          <source src={IntroBBC} />
        </video>
      </section>
      <Marquee
        style={{
          backgroundColor: "#212529",
          color: "white",
          padding: "30px 0 33px",
        }}
      >
        <div className="d-flex gap-3">
          <span><FaStarOfLife size={24} color="#87beff" /></span>
          <h4 className="mr-20">Digital Marketing</h4>
        </div>
        <div className="d-flex gap-3">
          <span><FaStarOfLife size={24} color="#87beff" /></span>
          <h4 className="mr-20">Web Development</h4>
        </div>
        <div className="d-flex gap-3">
          <span><FaStarOfLife size={24} color="#87beff" /></span>
          <h4 className="mr-20">BPO (Business Process Outsourcing)</h4>
        </div>
        <div className="d-flex gap-3">
          <span><FaStarOfLife size={24} color="#87beff" /></span>
          <h4 className="mr-20">Corporate Event Management</h4>
        </div>
        <div className="d-flex gap-3">
          <span><FaStarOfLife size={24} color="#87beff" /></span>
          <h4 className="mr-20">Comprehensive Tech Support</h4>
        </div>
        <div className="d-flex gap-3">
          <span><FaStarOfLife size={24} color="#87beff" /></span>
          <h4 className="mr-20">Cloud Computing Solutions</h4>
        </div>
        <div className="d-flex gap-3">
          <span><FaStarOfLife size={24} color="#87beff" /></span>
          <h4 className="mr-20">Financial Advisory Services</h4>
        </div>
        <div className="d-flex gap-3">
          <span><FaStarOfLife size={24} color="#87beff" /></span>
          <h4 className="mr-20">Business Consulting</h4>
        </div>
        <div className="d-flex gap-3">
          <span><FaStarOfLife size={24} color="#87beff" /></span>
          <h4 className="mr-20">Business Networking Services</h4>
        </div>
        <div className="d-flex gap-3">
          <span><FaStarOfLife size={24} color="#87beff" /></span>
          <h4 className="mr-20">IT Infrastructure Management</h4>
        </div>
      </Marquee>
      <section className="bg-light-gray pt-5 pb-5">
        <div className="img-content-parent container">
          <div className="img-content-container">
            <div className="overlay-image">
              <Img src={Connect} alt="Picture of hepling you grow faster." />
            </div>
            <div className="grow-faster-content">
              <h2>Helping you grow <u>faters</u>.</h2>
              <p>Organize, plan and amplify your social media strategy all in one place</p>
            </div>
          </div>
          <div className="free-consultation-form">
            <h3 className="mb-4">Get Your Free Consultation Now</h3>
            <form ref={form} onSubmit={sendEmail} className="d-flex flex-column gap-3">
              <input type="text" name="user_name" id="fullname" placeholder="Full Name" />
              <input type="email" name="user_email" id="email" placeholder="Email" />
              <input type="tel" name="user_number" id="number" placeholder="Phone Number" />
              <button type="submit" value="Send" className="get-now-btn">Get Now</button>
              {submitted && <p className="submit-message">Request submitted! We'll be in touch soon.</p>}
            </form>
          </div>
        </div>
      </section>
      <section className="double-content-parent pt-100 pb-100">
        <div className="container content-wrapper">
          <h2 className="wrapper-head-1">Let us together build a flourishing business</h2>
          <p className="wrapper-para mb-0">When connected with us, you aren’t growing your business alone. We have your back and put in our best to contribute to the growth of your entire team and organization. So, if you are looking for the right agency that’ll help you build a good online presence and bring in more conversions and revenue, we are right here!</p>
        </div>
      </section>
      <section className="bg-light-gray pt-5 pb-5">
        <div className="img-content-parent align-items-center container">
          <div className="growth-image">
            <Img src={GrowthImg} alt="Picture of togetherness" />
          </div>
          <div>
            <h2>Unlock your potential.</h2>
            <p>Set clear goals, to continuous learning, build strong networks, seek mentorship, and adapt to industry trends for career growth.</p>
          </div>
        </div>
      </section>

      {/* <BlockCard
        tagline={"Clients We’ve Worked With"}
        className="mt-n25 text-center"
      /> */}
      {/* <section className="pi-18 mt-n25">
        <div className="client-container ">
          <div className="growth-image-container">
            <Img src={Growth} alt="growth chart picture" />
          </div>
          <div className="growth-content">
            <h2 className="hero-second-heading">Helping you <br/>grow <span className="text-white"><u>faster.</u></span></h2>
            <p className="text-white">Organize, plan and amplify your social <br/>media strategy all in one place</p>
          </div>
        </div>
      </section> */}
      {/* <BlockCard tagline={"Get Starteded Now"} className="mt-n25" /> */}
      {/* <section>
        <div className="pi-18 mt-n25 pb-4">
          <div className="bg_get_start d-flex justify-content-evenly align-items-center p-35 radius-125">
            <div className="content_start">
              <h2 className="hero-second-heading w-60 get_started_header ">
              Unlock your <span className="text-white"><u>potential.</u></span>
              </h2>
              <p className="w-60">Set clear goals, to continuous learning, build strong networks, seek mentorship, and adapt to industry trends for career growth.</p>
            </div>
            <div className="image_start">
              <div className="imagestart mb-n150">
                <Img
                  src={startImage}
                  alt="image_start2"
                  className="image_start2"
                />
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section>
        <div className="build-container">
          <div className="d-flex justify-content-center  container sub-build-container">
            <p className="build-para text-justify font-24-res-20 weight-400 ">
              When connected with us, you aren’t growing your business alone. We
              have your back and put in our best to contribute to the growth of
              your entire team and organization. So, if you are looking for the
              right agency that’ll help you build a good online presence and
              bring in more conversions and revenue, we are right here!
            </p>
            <h2 className="build-header font-size text-start weight-600">
              Let us together build a flourishing business
            </h2>
          </div>
        </div>
      </section> */}
      {/* <BlockCard tagline={"Our Services"} className="mt-n42" /> */}
      <section>
        <div className="card-bg">
          <h2 className="weight-600 text-center">
            Our Services
          </h2>
          <p className="text-center weight-400">
            Elevate brand prominence, drive profitability
          </p>
          <Img className="top-left-border" src={TopLeftBorder} alt="top left border" />
          <Img className="bottom-right-border" src={BottomRightBorder} alt="bottom right border" />
          <div className="service-grid container">
            <Link to="/DigitalMarket" className="no-line">
              <ServiceCard
                imgUrl={DM}
                serviceNewColor="Digital"
                service="Marketing"
              />
            </Link>
            <Link to="/webdevelpment" className="no-line">
              <ServiceCard
                imgUrl={WD}
                serviceNewColor="Web"
                service="Development"
              />
            </Link>

            <Link to="/cloudComputing" className="no-line">
              <ServiceCard
                imgUrl={CC}
                serviceNewColor="Cloud"
                service="Computing"
              />
            </Link>

            <Link to="/Financial" className="no-line">
              <ServiceCard
                imgUrl={FA}
                serviceNewColor="Financial"
                service="Advisory"
              />
            </Link>

            <Link to="/BussinessNetworking" className="no-line">
              <ServiceCard
                imgUrl={BN}
                serviceNewColor="Business"
                service="Networking"
              />
            </Link>

            <Link to="/ITInfrastructure" className="no-line">
              <ServiceCard
                imgUrl={ITIM}
                serviceNewColor="IT Infrastructure"
                service="Management"
              />
            </Link>
          </div>
        </div>
      </section>
      <BlockCard
        tagline={"Why Choose BizzBuzz Creations?"}
        className="mt-n40"
      />
      <section>
        <div className="bbc-wrapper">
          <div className="bbc-container container">
            <BBCCard
              title="Industry Expertise"
              paragraph="As Prayagraj's leading digital marketing firm, we offer web design, branding, and online marketing for service providers and B2C & B2B businesses."
            />
            <BBCCard
              title="Experience"
              paragraph="Our expert team simplifies tasks and pipelines, delivering personalized software solutions to effectively address your unique business challenges and needs."
            />
            <BBCCard
              title="Personalized Solution"
              paragraph="Through targeted recommendations and customized methods, a personalized solution improves user experience and happiness by tailoring services to individual needs."
            />
            <BBCCard
              title="Diverse Expertise"
              paragraph="Our team specializes in consulting, development outsourcing, and tailored technology solutions, expertly managing complex tasks to solve your challenges."
            />
            <BBCCard
              title="Client-Centric Approach"
              paragraph="A client-centric approach prioritizes customer needs, fosters relationships, and tailors solutions, enhancing satisfaction and loyalty for long-term success."
            />
            <BBCCard
              title="Technology Adoption"
              paragraph="Technology adoption refers to the process of integrating new tools and innovations into practices, enhancing efficiency and productivity in various sectors."
            />
            <BBCCard
              title="Transparent Pricing"
              paragraph="Transparency pricing involves clearly communicating prices to customers, building trust, and reducing confusion about costs, ultimately enhancing customer satisfaction and loyalty"
            />
            <BBCCard
              title="Ongoing Support"
              paragraph="Our expert team streamlines complex tasks and pipelines, providing customized software solutions to effectively address and solve your business challenges."
            />
          </div>
        </div>
      </section>
      <BlockCard className="mb-n45" tagline={"Our Clients Review"} />
      <section>
        <div className="testimonial-parent pt-50">
          <div className="testimonial-page d-flex flex-column justify-content-center align-items-center gap-10 container">

            <div className="testimonial-container mb-30 mt-50">
              <TestimonialCard
                imgUrl={testi1}
                name="ITS Fibernet"
                desc="At ITS Fibernet, we are eager to underwrite Bizz Buzz Creation for their remarkable help. Their expertise ensured seamless connectivity, significantly benefiting our operations. Their support has been invaluable, and we wholeheartedly recommend their services."
              />
              <TestimonialCard
                imgUrl={testi2}
                name="Make Me Debt Free"
                desc="MakemedebtFree is grateful for the exceptional services provided by Bizz Buzz Creation. Their expertise and dedication have played a crucial role in our journey to financial freedom. We highly recommend their services to anyone seeking reliable support"
              />
              <TestimonialCard
                imgUrl={testi3}
                name="Avondale Finance"
                desc="Avondale Finance is pleased to share our experience with Bizz Buzz Creation. Their exceptional services have truly impressed us. Their professionalism and dedication have surpassed our expectations. We highly recommend Bizz Buzz Creation to anyone seeking reliable support."
              />
              <TestimonialCard
                imgUrl={testi4}
                name="UK Claims"
                desc="UKclaimsexpert is thrilled with the exceptional services offered by Bizz Buzz Creation. Their expertise and dedication have been invaluable in efficiently handling our claims. We highly recommend Bizz Buzz Creation to anyone in need of top-tier support."
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MainPage;
