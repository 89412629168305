import React from 'react'
import { FaArrowUpRightFromSquare, FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import "./Stripband.css"
import { Link } from 'react-router-dom';

const Stripband = () => {
    return (
        <div className='stipband'>
            <div className='d-flex justify-content-end align-items-center p-2'>
                <div className='d-flex gap-3 content-responsive'>
                    <div>
                        <a className='text-white text-decoration-none d-flex gap-1' href="tel:9956839879"><span><FaPhone /></span>
                            +91-8115585285</a>
                    </div>
                    <div>
                        <a className='text-white text-decoration-none d-flex gap-1' href="mailto:info@bizzbuzzcreations.com"><span><IoMdMail size={20} /> </span>
                            info@bizzbuzzcreations.com</a>
                    </div>
                    <button className='request_button'>
                        <Link to="/contact" className='text-dark text-decoration-none d-flex gap-2 px-1'><span><FaArrowUpRightFromSquare /></span>
                        Request a Demo</Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Stripband