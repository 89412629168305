import React from 'react'
import "./WebDevelopment.css"
import webImage from "../../Assets/image_main/Web development.jpg"
import DiscoverCard from '../../Component/DiscoverCard/DiscoverCard'
import UncoverCard from '../../Component/UncoverCard/UncoverCard'
import maintainImg from '../../Assets/maintenance.png'
import ecomm from '../../Assets/ecomm.png'
import custom from '../../Assets/custom.png'
import Goals from '../../Component/Goals/Goals'
import smile from '../../Assets/smile.png';
import tick from '../../Assets/tick.png';
import delivery from '../../Assets/delivery.png';
import icon from '../../Assets/icon (1).png';
import websubimge1 from "../../Assets/Boost Online presence.jpg"
import websubimge2 from "../../Assets/Enhance brand identity.jpg"
import websubimge3 from "../../Assets/Drive engagement.jpg"
import websubimge4 from "../../Assets/Streamline operations.jpg"
import websubimge5 from "../../Assets/image/ensure fast load times.jpg"
import websubimge6 from "../../Assets/image/implement security measures.jpg"
import DiscoverCardCenter from '../../Component/DiscoverCardCenter/DiscoverCardCenter'
import Custom_Website_Development from "../../Assets/icon/Custom Website Development (coding).jpg"
import Wordpress_Website_Development from "../../Assets/icon/Wordpress Website Development.jpg"
import E_commerce_website_development from "../../Assets/icon/E-commerce website development.jpg"
import Customer_Relationship_Management from "../../Assets/icon/Customer Relationship Management.jpg"
import Content_Management_Services_Development from "../../Assets/icon/content moderation.jpg"
import Portfolio_Development from "../../Assets/icon/Portfolio Development.jpg"
import Single_Page_Application from "../../Assets/icon/Single-Page Application (SPA) Development.jpg"
import Web_Performance_Optimization from "../../Assets/icon/Web Performance Optimization.jpg"
import User_Experience from "../../Assets/icon/User Experience (UX) Design.jpg"
import Web_Portal_Development from "../../Assets/icon/Web Portal Development.jpg"
import API_Development from "../../Assets/icon/API Development.jpg"
import User_Interface from "../../Assets/icon/User Interface (UI) Design.jpg"
import Ongoing_Maintenance_and_Support from "../../Assets/icon/ongoing maintainance and support.jpg"
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const WebDevelopment = () => {
  return (
    <>
      <Helmet>
        <title>Web Development Services | Coding | WordPress | API | UI & UX | CRM & CMS | E-Commerce Development Services</title>
        <meta
          name="description"
          content="Offering expert Web Development Services in Prayagraj and Allahabad, including Coding, WordPress, API, UI/UX, CRM & CMS, and E-Commerce. Best website development for all needs."
        />
        <meta
          name="keywords"
          content="web development services in prayagraj, best website development in prayagraj, SMM services in prayagraj, best SMM services in prayagraj, digital marketing services and agency in prayagraj, best digital marketing agency in prayagraj, digital marketing in prayagraj, cloud computing services in prayagraj, financial advisory services in prayagraj, BPO Outsourcing services in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
      </Helmet>
      <div className='webdevelpment_page d-flex align-items-center justify-content-center text-center flex_page'>
        {/* <div className='image_portion_web img-width '>
        <img src={webImage} alt='image' className='image_main' />
      </div> */}
        <div className='content_portion p-15'>
          <h2 className='weight-600 mb-3'>WEB DEVELOPMENT</h2>
          <h5 className='weight-500'>Web Development Services: From Wordpress to Coding</h5>
          <p className='weight-400 mb-5'>It offers various solutions, from user-friendly custom-coded applications to Wordpress sites. We specialize in pixel perfect, responsive design, e-commerce, SEO optimization, and full-stack development to your needs.
          </p>
          <button className="get_button"><Link className="text-decoration-none" to="/contact">Get Started</Link></button>
        </div>
      </div>
      <div className='web_portion'>
        <div className='head_portion'>
          <h1 className='font-20-res-16 weight-600 text-center mb-5'>Web Development Services We Offer</h1>
        </div>
        <div className='grid_portion'>
          <DiscoverCardCenter icon_image={Custom_Website_Development} title="Custom Website Development (coding)" paragraph="Custom website development involves creating the web solutions using coding languages like HTML, CSS, JavaScript, and frameworks like React.JS. Backend tech stack are Node.js, Express.js, and No-SQL like MongoDB, and SQLite for SQL databases. It ensures unique functionality, user experiences, and seamless integration with existing systems." />
          <DiscoverCardCenter icon_image={Wordpress_Website_Development} title="Wordpress Website Development" paragraph="WordPress website development involves creating and customizing sites using the WordPress platform. It includes theme selection, plugin integration, responsive design, SEO optimization, and content management for dynamic user experiences." />
          <DiscoverCardCenter icon_image={E_commerce_website_development} title="E-commerce website development" paragraph="E-commerce website development involves creating online platforms for buying and selling products. Key aspects include user-friendly design, secure payment processing, inventory management, SEO optimization, and mobile responsiveness to enhance customer experience." />
          <DiscoverCardCenter icon_image={Customer_Relationship_Management} title="Customer Relationship Management" paragraph="Systems for managing client data, streamlining communication, and strengthening bonds are called customer relationship management, or CRM systems. They enhance client pleasure, loyalty, and business growth by enhancing sales, marketing, and customer service." />
          <DiscoverCardCenter icon_image={Content_Management_Services_Development} title="Content Management Services Development" paragraph="Content management services development involves creating and implementing systems to efficiently organize, store, and manage digital content.Which technology we have use Headless CMS Wordpress These services & technology enhance collaboration, streamline workflows, and improve content accessibility for businesses and users." />
          <DiscoverCardCenter icon_image={Portfolio_Development} title="Portfolio Development" paragraph="Portfolio development involves curating and showcasing your best work to highlight skills, achievements, and expertise. It helps attract clients or employers by presenting a clear, organized snapshot of your capabilities." />
          <DiscoverCardCenter icon_image={Single_Page_Application} title="Single-Page Application (SPA) Development" paragraph="Single-Page Application (SPA) development focuses on creating web apps that load a single HTML page and dynamically update content without refreshing, providing a fast, seamless user experience similar to desktop apps.The technology we have uses a framework, like React.JS." />
          <DiscoverCardCenter icon_image={Web_Performance_Optimization} title="Web Performance Optimization" paragraph="Web Performance Optimization (WPO) involves improving website speed, responsiveness, and overall performance to enhance user experience, reduce load times, increase engagement, and improve SEO rankings for better online visibility and conversions." />
          <DiscoverCardCenter icon_image={User_Experience} title="User Experience (UX) Design" paragraph="User Experience (UX) Design focuses on enhancing user satisfaction by improving usability, accessibility, and interaction with products or services. It involves research, prototyping, and testing to create intuitive, efficient experiences." />
          <DiscoverCardCenter icon_image={User_Interface} title="User Interface (UI) Design" paragraph="User Interface (UI) Design focuses on creating visually appealing, user-friendly layouts for websites, apps, and software. It enhances user experience through intuitive navigation, clear visuals, and consistent design elements." />
          <DiscoverCardCenter icon_image={Web_Portal_Development} title="Web Portal Development" paragraph="Web portal development involves creating a centralized platform that provides users access to various services, information, and tools, enabling efficient communication, collaboration, and management for businesses and organizations." />
          <DiscoverCardCenter icon_image={API_Development} title="API Development" paragraph="API development involves designing and building interfaces that allow software applications to communicate. It includes defining endpoints, methods, data formats, and security protocols to enable smooth data exchange and functionality." />
        </div>

      </div>
      <div className='incover_portion pb-5'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Web development</h1>
        <div className='grid_portion m-auto align-items-center justify-content-center '>
          <UncoverCard image_uncover={websubimge1} title="Enhance User Experience (UX)" paragraph="The primary objective is to create websites that are user-friendly, intuitive, and provide a seamless experience across devices." />
          <UncoverCard image_uncover={websubimge2} title="Establish Online Presence" paragraph="A well-developed website serves as a digital storefront for businesses, enhancing their visibility and accessibility to a global audience." />
          <UncoverCard image_uncover={websubimge3} title="Improve Accessibility" paragraph="Ensuring that websites are accessible to everyone, including people with disabilities, is crucial. This can include features like screen reader support, keyboard navigation, and more." />
          <UncoverCard image_uncover={websubimge4} title="Increase Engagement and Conversions" paragraph="Effective web development integrates features that engage users, such as interactive elements, compelling design, and clear calls to action, aimed at driving conversions." />
          <UncoverCard image_uncover={websubimge5} title="Ensure Fast Load Times" paragraph="Speed is a critical factor in user retention. The objective is to optimize websites to load quickly, which improves user satisfaction and SEO rankings." />
          <UncoverCard image_uncover={websubimge6} title="Implement Security Measures" paragraph="Web development also focuses on incorporating security protocols (e.g., SSL encryption, data protection) to safeguard user data and maintain trust." />
        </div>

      </div>
      <div className='Page'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Web Development?</h1><div className="App">
          <div className="card-container container">
            <Goals logo_icon={smile} title="Personalized Solutions" paragraph="BBC specializes in crafting custom websites tailored to meet your unique business objectives, delivering a personalized approach to both design and functionality" />
            <Goals logo_icon={tick} title="Comprehensive Expertise " paragraph="With a team skilled in the latest web technologies and trends, BBC delivers high-performance websites that are optimized for user experience, speed, and SEO." />
            <Goals logo_icon={delivery} title="End-to-End Service" paragraph="From concept to launch, BBC offers a full spectrum of services, including design, development, testing, and post-launch support, making the entire process seamless." />
            <Goals logo_icon={icon} title="Responsive and Secure Designs" paragraph="BBC prioritizes mobile responsiveness and security, ensuring your website looks great on all devices and is protected against cyber threats with seamless user experience" />
            <Goals logo_icon={Ongoing_Maintenance_and_Support} title="Ongoing Maintenance and Support" paragraph="As a company offering hardware maintenance services, BBC extends this reliability to web development with continuous support, updates, and troubleshooting to keep your site running smoothly." />
          </div>
        </div>
      </div>
      {/* <div style={{ background: "#d4dfe3", padding: "20px 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-10 align-items-center pt-5 flex_page'>
            <div className='img-width'>
              <img src={custom} alt="ecomm website" className='image' />
            </div>
            <div className=''>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Custom Website Development</h2>
              <p className='font-18-res-16 weight-400 '>Welcome to Bizz Buzz Creation, where your online presence meets innovation and excellence. Our custom website development service is designed to elevate your digital footprint and propel your business to new heights.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Web Development?</h2>
          <DiscoverCard title="Personalized Solutions:" paragraph="We understand that every business is unique, and cookie-cutter solutions won't suffice. Our custom website development service crafts bespoke websites that align perfectly with your brand identity, goals, and target audience." />
          <DiscoverCard title="Comprehensive Expertise:" paragraph="A seamless user experience is paramount for driving engagement and conversions. With our expertise in user interface (UI) and user experience (UX) design, we ensure that your website not only looks stunning but also functions flawlessly across all devices." />
          <DiscoverCard title="End-to-End Service:" paragraph="As your business develops, so should your site. Our custom solutions are built with scalability and flexibility in mind, allowing for easy integration of new features, updates, and expansions as your business grows." />
          <DiscoverCard title="Responsive and Secure Designs:" paragraph="In the present swarmed computerized scene, it is vital to stand apart from the opposition. Our custom websites are designed to captivate your audience and leave a lasting impression, giving you a distinct competitive edge." />
          <DiscoverCard title="Ongoing Maintenance and Support:" paragraph="As a company offering hardware maintenance services, BBC extends this reliability to web development with continuous support, updates, and troubleshooting to keep your site running smoothly."/>  
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our services can help you ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Establish Online Presence</h3>
              <p className='font-18-res-16 weight-400'>Whether you're a startup looking to make a splash or an established brand aiming to enhance your online presence, our custom website development service empowers you to create a strong and memorable digital footprint.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Drive Conversions</h3>
              <p className='font-18-res-16 weight-400 mb-5'>A well-designed website is your most powerful marketing tool. By focusing on user experience and conversion optimization, we help you turn visitors into customers and achieve your business objectives.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Build Brand Credibility</h3>
              <p className='font-18-res-16 weight-400 mb-5'>Your website is often the first point of contact between your brand and potential customers. With our custom solutions, you can instill trust, credibility, and professionalism, leaving a lasting impression on your audience.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Adapt to Changing Trends</h3>
              <p className='font-18-res-16 weight-400 mb-5'>The digital landscape is constantly evolving, and so should your website. Our team stays abreast of the latest trends and technologies to ensure that your website remains relevant, engaging, and ahead of the curve.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Web development</h2>
          <DiscoverCard title="Enhance User Experience (UX):" paragraph="The primary objective is to create websites that are user-friendly, intuitive, and provide a seamless experience across devices." />
          <DiscoverCard title="Establish Online Presence:" paragraph="A well-developed website serves as a digital storefront for businesses, enhancing their visibility and accessibility to a global audience." />
          <DiscoverCard title="Improve Accessibility:" paragraph="Ensuring that websites are accessible to everyone, including people with disabilities, is crucial. This can include features like screen reader support, keyboard navigation, and more." />
          <DiscoverCard title="Increase Engagement and Conversions:" paragraph="Effective web development integrates features that engage users, such as interactive elements, compelling design, and clear calls to action, aimed at driving conversions." />
          <DiscoverCard title="Ensure Fast Load Times:" paragraph="Speed is a critical factor in user retention. The objective is to optimize websites to load quickly, which improves user satisfaction and SEO rankings."/>
          <DiscoverCard title="Implement Security Measures:" paragraph="Web development also focuses on incorporating security protocols (e.g., SSL encryption, data protection) to safeguard user data and maintain trust."/>
        </div>
      </div>

      <div style={{ background: "#eeeeee", padding: "20px 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
            <div className='img-width'>
              <img src={ecomm} alt="ecomm website" className='image' />
            </div>
            <div className=''>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>E-commerce Website Development</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, your go-to destination for cutting-edge E-commerce website development services! In today's fast-paced digital world, having a strong online presence is crucial for any business to thrive. That's where we come in – we're here to transform your vision into a stunning and highly functional E-commerce website that will set you apart from the competition.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose Our E-commerce Web Development? Let's Break It Down!</h2>
          <DiscoverCard title="Professionalism" paragraph="Our team of experienced developers and designers are experts in their field. With years of experience under their belts, they know exactly how to create a website that not only looks amazing but also functions seamlessly to provide the best user experience possible." />
          <DiscoverCard title="Customization" paragraph="We understand that every business is unique, which is why we offer fully customizable E-commerce solutions tailored to your specific needs and requirements. Whether you're a small boutique or a large enterprise, we'll work closely with you to create a website that reflects your brand identity and effectively showcases your products or services." />
          <DiscoverCard title="Mobile Responsiveness" paragraph="With an increasing number of consumers shopping on their smartphones and tablets, having a mobile-responsive website is more important than ever. Our E-commerce websites are optimized for all devices, ensuring that your customers can easily browse and make purchases on the go." />
          <DiscoverCard title="SEO-Friendly" paragraph="What good is a beautiful website if no one can find it? Our E-commerce websites are built with search engine optimization (SEO) in mind, helping you rank higher in search engine results and attract more organic traffic to your site." />
          <DiscoverCard title="Security" paragraph="We take the security of your Internet business site genuinely. That's why we implement the latest security measures and encryption protocols to protect your customers' sensitive information and give them peace of mind when making purchases online." />
          <DiscoverCard title="Adaptability" paragraph="As your business develops, your site needs to develop with it. Our E-commerce solutions are highly scalable, allowing you to easily add new products, update content, and expand functionality as needed without any hassle.
        At Bizz Buzz Creation, our objective is simple to help you succeed in the digital marketplace. Whether you're just starting out or looking to revamp your existing E-commerce website, we're here to provide you with the tools and expertise you need to thrive online. Get in touch with us today to learn more about our E-commerce website development services and take the first step towards online success!" />
        </div>
      </div>

      <div style={{ background: "#d4dfe3", padding: "20px 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
            <div className='img-width'>
              <img src={maintainImg} alt="website maintenance" className='image' />
            </div>
            <div className=''>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Website Maintenance and support</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation's Website Maintenance and Support service! We understand that in today's digital age, your website is often the first point of contact for potential customers. That's why it's crucial to keep your online presence fresh, functional, and secure. Our comprehensive maintenance and support solutions are designed to take the hassle out of website management, allowing you to focus on what you do best – running your business.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Advantages of using our website maintenance and support system</h2>
          <DiscoverCard title="Peace of Mind" paragraph="Say goodbye to the stress of dealing with website issues. Our team of experienced professionals will proactively monitor your site, ensuring it runs smoothly and remains secure at all times." />
          <DiscoverCard title="Improved Performance" paragraph="A well-maintained website performs better. We'll optimize your site's speed and functionality, providing your visitors with a seamless browsing experience that keeps them coming back for more." />
          <DiscoverCard title="Regular Updates" paragraph="From software updates to content changes, we'll handle all the necessary updates to keep your website current and relevant. This includes updating plugins, security patches, and content management system (CMS) upgrades." />
          <DiscoverCard title="Enhanced Security" paragraph="Protecting your website from cyber threats is essential in today's digital landscape. Our team will implement robust security measures to safeguard your site against hackers, malware, and other online risks." />
          <DiscoverCard title="Customized Solutions" paragraph="We understand that every business is unique. That's why we offer tailored maintenance and support packages to suit your specific needs and budget. Whether you're a little startup or an enormous venture, we take care of you." />

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How our Website Maintenance and Support Service can help you</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Save Time and Resources</h3>
              <p className='font-18-res-16 weight-400 mb-5'>Outsourcing your website maintenance allows you to focus on core business activities, saving you time and resources in the long run.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Stay Competitive</h3>
              <p className='font-18-res-16 weight-400 mb-5'>A well-maintained website not only attracts more visitors but also helps you stay ahead of the competition. With our support, you can ensure your site remains competitive in today's fast-paced digital marketplace.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Reduce Downtime</h3>
              <p className='font-18-res-16 weight-400 mb-5'>Our proactive approach to maintenance helps minimize downtime, ensuring your website is always up and running when your customers need it most.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Scale With Confidence</h3>
              <p className='font-18-res-16 weight-400 mb-5'>Whether you're experiencing rapid growth or seasonal fluctuations in traffic, our maintenance and support services can scale alongside your business, providing you with the flexibility you need to succeed.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Website Maintenance and Support Service</h2>
          <div className='mb-5'>
          <DiscoverCard title="Customer Satisfaction" paragraph="Our primary objective is to ensure the satisfaction of our clients by delivering high-quality maintenance and support services that exceed their expectations." />
          <DiscoverCard title="Website Reliability" paragraph="We aim to maximize the reliability of our clients' websites by implementing proactive maintenance strategies and promptly addressing any issues that may arise." />
          <DiscoverCard title="Long-Term Partnerships" paragraph="We strive to build long-term partnerships with our clients based on trust, transparency, and mutual respect. By consistently delivering exceptional service, we aim to become your trusted partner in website management." />
          
          <DiscoverCard title="Continuous Improvement" paragraph="We are committed to continuously improving our maintenance and support services by staying abreast of the latest industry trends and technologies. This allows us to provide our clients with cutting-edge solutions that keep them ahead of the curve." />
          <br/>
          <DiscoverCard title="" paragraph="With Bizz Buzz Creation's Website Maintenance and Support service, you can rest assured that your website is in good hands. Contact us today to learn more about how we can help you maximize your online presence and grow your business." />
        </div>
        </div>
      </div> */}
    </>
  )
}

export default WebDevelopment