import React from 'react'
import UncoverCard from '../../Component/UncoverCard/UncoverCard'
import custom from "../../Assets/BN2.png"
import custom2 from "../../Assets/BN3.png"
import custom3 from "../../Assets/BN4.png"
import webImage from "../../Assets/image_main/Business Networking.jpg"
import DiscoverCard from '../../Component/DiscoverCard/DiscoverCard'
import BN1 from "../../Assets/Targeted networking events.jpg"
import BN2 from "../../Assets/Tailored networking solutions.avif"
import BN3 from "../../Assets/Mentorship and coaching.avif"
import BN4 from "../../Assets/Collaborative  opportunities.avif"
import DiscoverCardCenter from '../../Component/DiscoverCardCenter/DiscoverCardCenter'
import Goals from '../../Component/Goals/Goals'
import Networking_Events from "../../Assets/icon/network event.jpg"
import Business_Matchmaking from "../../Assets/icon/business matchmaking.jpg"
import Online_Networking_Platforms from "../../Assets/icon/online networking platforms.jpg"
import Membership_Programs from "../../Assets/icon/membership programs.jpg"
import Training_and_Workshops from "../../Assets/icon/training and workshops.jpg"
import Social_Media_Networking_Strategies from "../../Assets/icon/social media networking strategies.jpg"
import Collaborative_Projects from "../../Assets/icon/collabrative projects.jpg"
import Networking_Strategy_Development from "../../Assets/icon/networking strategy development.jpg"
import Personalized_Networking_Solutions from "../../Assets/icon/personalized networking solution.jpg"
import Extensive_Network_Access from "../../Assets/icon/extensive network access.jpg"
import Expert_Facilitation from "../../Assets/icon/expert facilitation.jpg"
import Comprehensive_Resources from "../../Assets/icon/comprehensive resource.jpg"
import Focus_on_Results from '../../Assets/icon/focus on results.jpg'
import Ongoing_Support from '../../Assets/icon/ongoing support.jpg'
import Build_Strategic_Partnerships from '../../Assets/image/build strategic partnership.jpg'
import Expand_Market_Reach from '../../Assets/image/expand market reach.jpg'
import Gain_Industry_Insights from '../../Assets/image/gain industry insights.jpg'
import Enhance_Reputation_and_Credibility from '../../Assets/image/enhance reputation and credibility.jpg'
import Access_Mentorship_and_Support from '../../Assets/image/access mentorship and support.jpg'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'


function BusinessNetworking() {
  return (
    <>
      <Helmet>
        <title>Connect & Grow: Your Business Networking Center</title>
        <meta
          name="description"
          content="Join our networking center to connect with industry leaders, share insights, and grow your business through valuable relationships and collaboration."
        />
        <meta
          name="keywords"
          content="web development services in prayagraj, best website development in prayagraj, SMM services in prayagraj, best SMM services in prayagraj, digital marketing services and agency in prayagraj, best digital marketing agency in prayagraj, digital marketing in prayagraj, cloud computing services in prayagraj, financial advisory services in prayagraj, BPO Outsourcing services in Prayagraj"
        />
        <meta name="author" content="BizzBuzzCreations" />
      </Helmet>
      <div className="webdevelpment_page d-flex align-items-center justify-content-center text-center flex_page">
        {/* <div className="image_portion_web img-width">
      <img src={webImage} alt="image" className="image_main" />
    </div> */}
        <div className="content_portion p-15">
          <h2 className="weight-600 mb-3">Business Networking</h2>
          <h5 className="weight-500">
            Welcome to Bizz Buzz Creation, your premier source for comprehensive business networking.
          </h5>
          <p className="weight-400 mb-5">
            Business networking involves building relationships and connections among professionals and organizations to share resources, collaborate, and create opportunities, ultimately enhancing growth, visibility, and access to new markets and clients.
          </p>
          <button className="get_button"><Link className="text-decoration-none" to="/contact">Get Started</Link></button>
        </div>
      </div>
      <div className='web_portion'>
        <div className='head_portion'>
          <h1 className='font-20-res-16 weight-600 text-center mb-5'>Business Networking Services We Offer</h1>
        </div>
        <div className='grid_portion'>
          <DiscoverCardCenter icon_image={Networking_Events} title="Networking Events" paragraph="Networking events are gatherings designed to connect professionals, entrepreneurs, and businesses. They facilitate relationship-building, collaboration, and knowledge sharing, providing valuable opportunities for partnerships, referrals, and business growth." />
          <DiscoverCardCenter icon_image={Business_Matchmaking} title="Business Matchmaking" paragraph="Business matchmaking connects companies and professionals with complementary goals, facilitating partnerships, collaborations, and opportunities. It streamlines networking by identifying suitable matches based on shared interests, industries, and expertise." />
          <DiscoverCardCenter icon_image={Online_Networking_Platforms} title="Online Networking Platforms" paragraph="Online networking platforms facilitate professional connections and collaboration through digital channels, enabling users to share knowledge, resources, and opportunities, enhancing visibility and expanding networks across various industries and geographic locations." />
          <DiscoverCardCenter icon_image={Membership_Programs} title="Membership Programs" paragraph="Membership programs offer individuals or businesses exclusive access to resources, events, and networking opportunities. They foster community engagement, enhance professional development, and provide valuable benefits tailored to members' interests and goals." />
          <DiscoverCardCenter icon_image={Training_and_Workshops} title="Training and Workshops" paragraph="Workshops and training sessions offer practical learning opportunities aimed at improving competencies, knowledge, and abilities in particular fields. They promote teamwork, practical application, and professional growth, which boosts employee engagement and company performance." />
          <DiscoverCardCenter icon_image={Social_Media_Networking_Strategies} title="Social Media Networking Strategies" paragraph="Social media networking strategies focus on leveraging platforms like Facebook, LinkedIn, and Twitter to build relationships, engage audiences, share content, and enhance brand visibility, driving business growth and customer engagement." />
          <DiscoverCardCenter icon_image={Collaborative_Projects} title="Collaborative Projects" paragraph="Collaborative projects involve multiple organizations or teams working together towards a common goal, sharing resources, expertise, and knowledge. This approach creativity,  innovation,  problem-solving, and leads to greater overall success." />
          <DiscoverCardCenter icon_image={Networking_Strategy_Development} title="Networking Strategy Development" paragraph="Networking strategy development focuses on creating tailored plans for building professional relationships, identifying key contacts, and leveraging opportunities, enhancing collaboration, visibility, and growth within targeted industries or markets." />
          {/* <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/>
        <DiscoverCardCenter title="" paragraph=""/> */}
        </div>

      </div>
      <div className='incover_portion pb-5'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objective of Business Networking?</h1>
        <div className='grid_portion m-auto align-items-center justify-content-center '>
          <UncoverCard image_uncover={Build_Strategic_Partnerships} title="Build Strategic Partnerships" paragraph="Establish mutually beneficial relationships with other businesses, professionals, and industry leaders to create new opportunities for collaboration, growth, and resource sharing." />
          <UncoverCard image_uncover={Expand_Market_Reach} title="Expand Market Reach" paragraph="Increase visibility and broaden the customer base by connecting with potential clients, partners, and influencers in the industry, leading to new business prospects." />
          <UncoverCard image_uncover={Gain_Industry_Insights} title="Gain Industry Insights" paragraph="Stay informed about the latest market trends, best practices, and emerging opportunities by engaging with other professionals and exchanging knowledge and experiences." />
          <UncoverCard image_uncover={Enhance_Reputation_and_Credibility} title="Enhance Reputation and Credibility" paragraph="Build a strong business reputation by actively participating in networking events and maintaining relationships, establishing your company as a reliable and credible player in the industry." />
          <UncoverCard image_uncover={Access_Mentorship_and_Support} title="Access Mentorship and Support" paragraph="Connect with experienced professionals who can offer guidance, mentorship, and advice, helping to navigate challenges and foster business growth." />
          {/* <UncoverCard image_uncover={""} title="" paragraph=""/> */}
        </div>

      </div>
      <div className='Page'>
        <h1 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Business Networking Services ?</h1><div className="App">
          <div className="card-container container">
            <Goals logo_icon={Personalized_Networking_Solutions} title="Personalized Networking Solutions" paragraph="BBC designs customized networking strategies that align with your business goals, ensuring meaningful connections with the right professionals and organizations." />
            <Goals logo_icon={Extensive_Network_Access} title="Extensive Network Access" paragraph="Tap into BBC's extensive network of industry contacts and partners, unlocking valuable opportunities for collaboration, strategic partnerships, and business growth." />
            <Goals logo_icon={Expert_Facilitation} title="Expert Facilitation" paragraph="Our experienced team facilitates networking events and workshops, creating an engaging environment that fosters genuine connections and interactions among participants." />
            <Goals logo_icon={Comprehensive_Resources} title="Comprehensive Resources" paragraph="BBC offers tools, resources, and training to improve your networking skills, helping you build and sustain meaningful professional relationships for long-term success." />
            <Goals logo_icon={Focus_on_Results} title="Focus on Results" paragraph="We emphasize measurable outcomes, providing guidance to help you achieve specific networking objectives that align with your strategic goals, ultimately contributing to your business's long-term success, growth, and sustainability." />
            <Goals logo_icon={Ongoing_Support} title="Ongoing Support" paragraph="BBC provides ongoing support and guidance, helping you navigate your networking journey and adapt strategies to maximize opportunities for growth and success." />
          </div>
        </div>
      </div>

      {/* <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className="img-width hr_hero">
            <img src={custom} alt="ecomm website" className='imagehr' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Business Networking Events</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, your ultimate partner for organizing impactful business networking events. In today’s fast-paced business environment, creating and leveraging professional connections is crucial for growth and success. Our business networking events are designed to bring together industry leaders, entrepreneurs, and professionals to foster meaningful connections, share insights, and explore new opportunities. Here’s why our events are essential, how they can benefit you, and our objectives in providing these services.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Business Networking Services ?</h2>
          <DiscoverCard title="Personalized Networking Solutions" paragraph="Our events provide an excellent platform to expand your professional network. You'll meet like-minded individuals, potential clients, and industry experts who can contribute to your business growth and personal development." />
          <DiscoverCard title="Extensive Network Access" paragraph="Networking events are an opportunity to share and gain knowledge. Engaging with others in your field allows you to exchange ideas, learn about the latest industry trends, and stay ahead of the competition." />
          <DiscoverCard title="Expert Facilitation" paragraph="Attending and actively participating in our networking events increases your visibility in your industry. It’s a chance to showcase your expertise, build your brand, and establish yourself as a thought leader." />
          <DiscoverCard title="Comprehensive Resources" paragraph="Our events are designed to foster collaboration. Whether you’re looking for new business partners, joint ventures, or collaborative projects, you’ll find plenty of opportunities to explore potential collaborations." />
          <DiscoverCard title="Focus on Results" paragraph="Our events often include workshops, panel discussions, and guest speakers, providing valuable learning experiences. These sessions can help you develop new skills, gain insights from successful leaders, and apply best practices to your business." />
          <DiscoverCard title="Ongoing Support" paragraph="BBC offers continuous support and guidance, assisting you in navigating your networking journey and adapting strategies to maximize opportunities."/>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How Our Business Networking Events Services Can Help You ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Tailored Networking Opportunities</h3>
              <p className='font-18-res-16 weight-400'>We carefully curate our events to match your specific networking needs. Whether you’re a startup looking to connect with investors or an established business seeking new clients, our events provide targeted networking opportunities.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Strategic Networking and Quality Events</h3>
              <p className='font-18-res-16 weight-400'>We facilitate introductions and connections aligned with your business goals, ensuring that our events are of the highest quality. Our team works to understand your objectives and connects you with the right people to drive your business forward. From venue selection to event promotion, we handle all the details to create a memorable and productive experience with engaging content and a welcoming atmosphere.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Support and Follow-Up</h3>
              <p className='font-18-res-16 weight-400'>Our services don’t end when the event does. We provide ongoing support and follow-up to help you nurture the connections you make. Whether it’s through post-event meetups or online networking platforms, we help you maintain and grow your new relationships.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Access to Resources</h3>
              <p className='font-18-res-16 weight-400'>Attendees of our events gain access to valuable resources, including industry reports, white papers, and exclusive content. These resources are designed to help you stay informed and make informed business decisions.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Business Networking Events Service</h2>
          <DiscoverCard title="Building a Community" paragraph="Our primary objective is to build a thriving community of professionals who support and inspire each other. We aim to create an environment where meaningful connections are made, and collaboration is encouraged." />
          <DiscoverCard title="Facilitating Business Growth" paragraph="We aim to facilitate the growth of your business by providing a platform where you can meet potential clients, partners, and investors. Our events are designed to open doors to new opportunities and help you achieve your business goals." />
          <DiscoverCard title="Encouraging Knowledge Exchange" paragraph="We are committed to encouraging the exchange of knowledge and ideas. By bringing together diverse perspectives, our events foster innovation and help you stay ahead of industry trends." />
          <DiscoverCard title="Promoting Professional Development" paragraph="Our objective is to promote professional development through our events. By offering workshops, seminars, and mentorship opportunities, we help you enhance your skills and advance your career." />
          <DiscoverCard title="Enhancing Brand Visibility" paragraph="We aim to enhance your brand visibility and reputation. By providing a platform for you to showcase your expertise and connect with industry leaders, our events help you build a strong professional presence." />
          <DiscoverCard title="" paragraph="At Bizz Buzz Creation, we are dedicated to organizing exceptional business networking events that provide real value to our attendees. Whether you’re looking to expand your network, gain insights, or explore new opportunities, our events are designed to help you achieve your objectives." />
          <DiscoverCard title="" paragraph="Contact us today to learn more about our upcoming business networking events and how you can benefit from participating in these dynamic and engaging gatherings." />
        </div>
      </div>

      <div style={{ background: "#EEEEEE", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className="img-width hr_hero">
            <img src={custom2} alt="ecomm website" className='imagehr' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Online Webinars and Virtual Networking</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, your premier destination for online webinars and virtual networking events. In an increasingly digital world, the ability to connect, learn, and grow through virtual platforms is more important than ever. Our online webinars and virtual networking services are designed to bring together professionals from around the globe, providing a rich environment for learning and collaboration. Here’s why our services are essential, how they can benefit you, and our objectives in providing them.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Online Webinars and Virtual Networking ?</h2>
          <DiscoverCard title="Global Reach" paragraph="Our online platforms allow you to connect with professionals from all over the world. This global reach expands your network beyond geographical limitations, opening up a wealth of opportunities for collaboration and growth." />
          <DiscoverCard title="Convenience and Accessibility" paragraph="Participate in our events from the comfort of your home or office. Our webinars and virtual networking sessions are accessible from any device with an internet connection, making it easy to fit into your busy schedule." />
          <DiscoverCard title="Cost-Effective" paragraph="Virtual events eliminate travel and accommodation costs, making professional development more affordable. This allows you to access high-quality content and networking opportunities without breaking the bank." />
          <DiscoverCard title="Interactive Learning" paragraph="Our webinars are designed to be interactive and engaging. With features like live Q&A sessions, polls, and breakout rooms, you can actively participate, ask questions, and engage with speakers and other attendees." />
          <DiscoverCard title="Resource Availability" paragraph="We provide access to a wealth of resources, including recorded sessions, presentation materials, and additional reading. This allows you to revisit the content at your own pace and continue learning long after the event has ended." />

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How Our Online Webinars and Virtual Networking Services Can Help You ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Expert-Led Webinars</h3>
              <p className='font-18-res-16 weight-400'>Our webinars feature industry experts and thought leaders who share their insights on a variety of topics. Whether you’re looking to enhance your skills, stay updated on industry trends, or gain new knowledge, our expert-led sessions provide valuable learning opportunities.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Interactive Networking for Career Growth</h3>
              <p className='font-18-res-16 weight-400'>Participate in our virtual networking events designed for meaningful connections and career advancement. Through structured sessions, breakout rooms, and interactive chats, meet professionals, share ideas, and build valuable relationships. Gain new skills, knowledge, and connections through webinars and events, whether you're seeking mentorship, job opportunities, or career exploration.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Flexible Learning Environment</h3>
              <p className='font-18-res-16 weight-400'>Our online format offers flexibility that traditional events can’t match. You can attend live sessions or access recordings at your convenience, allowing you to balance professional development with your other commitments.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Enhanced Engagement</h3>
              <p className='font-18-res-16 weight-400'>Our virtual events are designed to be highly engaging, with interactive features that allow for real-time participation and feedback. This ensures a dynamic and immersive experience that keeps you engaged and maximizes your learning.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Online Webinars and Virtual Networking Services</h2>
          <DiscoverCard title="Facilitating Continuous Learning" paragraph="Our primary objective is to facilitate continuous learning and professional development. We aim to provide high-quality educational content that helps you stay current with industry trends, develop new skills, and enhance your knowledge." />
          <DiscoverCard title="Fostering Global Connections" paragraph="We aim to foster a global community of professionals by providing a platform for virtual networking. Our events connect you with peers, mentors, and industry leaders from around the world, expanding your network and creating opportunities for collaboration." />
          <DiscoverCard title="Promoting Knowledge Exchange" paragraph="We aim to promote the exchange of knowledge and ideas through our webinars and networking sessions. By bringing together diverse perspectives, we create an environment where innovation and creativity can thrive." />
          <DiscoverCard title="Supporting Career Growth" paragraph="Our objective is to support your career growth by providing access to expert insights, professional development opportunities, and valuable connections. We strive to create events that help you achieve your career goals and advance in your field." />
          <DiscoverCard title="Enhancing Accessibility" paragraph="We aim to make professional development accessible to everyone, regardless of location or financial constraints. Our online format ensures that high-quality content and networking opportunities are available to a wide audience, promoting inclusivity and equal opportunities for growth." />
          <DiscoverCard title="" paragraph="At Bizz Buzz Creation, we are committed to delivering exceptional online webinars and virtual networking events that provide real value to our participants. Whether you’re looking to learn, connect, or grow, our events are designed to help you achieve your professional objectives." />
          <DiscoverCard title="" paragraph="Contact us today to learn more about our upcoming online webinars and virtual networking events and discover how you can benefit from participating in these dynamic and engaging experiences." />
        </div>
      </div>

      <div style={{ background: "#d4dfe3", padding: "0 0 20px 0" }}>
        <div className='container'>
          <div className='d-flex gap-5 align-items-center pt-5 flex_page'>
          <div className="img-width hr_hero">
            <img src={custom3} alt="ecomm website" className='imagehr' />
            </div>
            <div className='text-start'>
              <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Business Matching</h2>
              <p className='font-18-res-16 weight-400'>Welcome to Bizz Buzz Creation, where we specialize in providing exceptional business matching services designed to connect you with the right partners, clients, and opportunities to drive your business forward. In today's competitive market, finding the right business matches can significantly impact your success. Our business matching services are crafted to facilitate meaningful connections, fostering growth and collaboration. Here’s why our services are essential, how they can benefit you, and our objectives in providing them.</p>
            </div>
          </div>
          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Why Choose BBC for Business Matching ?</h2>
          <DiscoverCard title="Targeted Connections" paragraph="Our service matches you with partners, clients, and collaborators who align with your business goals and needs. This targeted approach ensures that you are connecting with the right people, saving time and resources." />
          <DiscoverCard title="Market Expansion" paragraph="By connecting with new partners and clients, you can expand your market reach. Our business matching services help you explore new markets and customer bases, driving growth and diversification." />
          <DiscoverCard title="Increased Efficiency" paragraph="We streamline the process of finding business matches, handling the legwork of identifying and vetting potential partners. This allows you to focus on building relationships and growing your business." />
          <DiscoverCard title="Enhanced Opportunities" paragraph="Access to a curated network of professionals and companies opens up new opportunities for collaboration, investment, and innovation. Our service helps you unlock potential that might otherwise go unnoticed." />
          <DiscoverCard title="Competitive Advantage" paragraph="By connecting with the right partners and clients, you gain a competitive edge. Our services help you stay ahead of the curve by fostering strategic alliances that enhance your market position." />

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>How Our Business Matching Services Can Help You ?</h2>
          <div className='grid_portion' style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)",  gap: "10px", textAlign: "center" }}>
            <div>
              <h3 className='font-20-res-16 weight-600'>Tailored Connections for Success</h3>
              <p className='font-18-res-16 weight-400'>Benefit from our customized matching process and extensive network access. Through detailed profiling, we understand your business needs, matching you with partners and clients who align with your criteria. Gain access to a diverse range of potential partners across industries and regions, from suppliers and distributors to investors and clients.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Facilitated Introductions</h3>
              <p className='font-18-res-16 weight-400'>We handle the introductions, providing you with a seamless connection process. From initial contact to setting up meetings, we ensure that your interactions are smooth and productive.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Ongoing Support</h3>
              <p className='font-18-res-16 weight-400'>Our support doesn’t end with the initial match. We provide ongoing assistance to help you nurture and develop these relationships, offering advice and resources to ensure successful collaborations.</p>
            </div>
            <div>
              <h3 className='font-20-res-16 weight-600'>Insightful Analytics</h3>
              <p className='font-18-res-16 weight-400'>We provide detailed analytics and feedback on your matches, helping you understand the potential and performance of each connection. This data-driven approach enables you to make informed decisions about your business partnerships.</p>
            </div>
          </div>

          <h2 className='font-32-res-22 weight-600  text-center mt-5 mb-50'>Objectives of Providing Business Matching Services</h2>
          <div className='mb-5'>
          <DiscoverCard title="Facilitating Growth and Expansion" paragraph="Our primary objective is to facilitate your business growth and expansion. By connecting you with the right partners and clients, we help you explore new opportunities and achieve your business goals." />
          <DiscoverCard title="Enhancing Collaboration" paragraph="We aim to enhance collaboration within and across industries. Our services promote the sharing of ideas, resources, and expertise, fostering an environment of innovation and mutual benefit." />
          <DiscoverCard title="Supporting Market Entry" paragraph="For businesses looking to enter new markets, our matching services provide essential support. We connect you with local partners who can help you navigate new markets, ensuring a smoother and more successful entry." />
          <DiscoverCard title="Promoting Efficiency" paragraph="We strive to make the process of finding and connecting with business partners as efficient as possible. By handling the matchmaking process, we save you time and resources, allowing you to focus on what you do best." />
          <DiscoverCard title="Building a Thriving Business Community" paragraph="Our ultimate goal is to build a thriving community of businesses that support and grow together. We believe that by fostering strong connections, we can create a network of businesses that are resilient, innovative, and successful." />
          <br/>
          <DiscoverCard title="" paragraph="At Bizz Buzz Creation, we are dedicated to helping you find the perfect business matches that align with your goals and vision. Our business matching services are designed to provide you with the connections, support, and opportunities you need to succeed." />
          <br/>
          <DiscoverCard title="" paragraph="Contact us today to learn more about our business matching services and discover how we can help you build strategic and impactful business relationships." />
        </div>
      </div>
      </div> */}
    </>
  )
}

export default BusinessNetworking